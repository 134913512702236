import { useState } from "react";

export default function PromoBanner({ data }) {
  const [isOpen, setIsOpen] = useState(true);
  return (
    isOpen && (
      <div className="container promo-22-banner fx fx--cv" id="promo-div">
        <a
          className="content-div fx fx--tb"
          href="https://www.setmore.com/blog/migrate-booking-system/?utm_source=homepage&utm_medium=heroCta&utm_campaign=homepage_fresh_start"
        >
          {/* Desktop Message */}
          <span
            className="details desktop-message"
            dangerouslySetInnerHTML={{
              __html: data?.desktopMessage,
            }}
          ></span>

          {/* Mobile Message */}
          <span
            className="details mobile-message"
            dangerouslySetInnerHTML={{
              __html: data?.mobileMessage,
            }}
          ></span>
        </a>
        <span className="close-element" onClick={() => setIsOpen(false)}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.613 4.21a1 1 0 0 0-1.32 1.497l6.291 6.291-6.291 6.292-.083.094a1 1 0 0 0 1.497 1.32l6.291-6.291 6.292 6.291.094.083a1 1 0 0 0 1.32-1.497l-6.291-6.292 6.291-6.29.083-.095a1 1 0 0 0-1.497-1.32l-6.292 6.291-6.29-6.291-.095-.083z"
              fill="#05857c"
              opacity=".5"
            ></path>
          </svg>
        </span>
      </div>
    )
  );
}
